nav a{
    color: aliceblue;
    text-overflow: none;
    font-size: 24px;
    margin-right: 10px;
    text-decoration: none;
    font-weight: bolder;
    font-size: 17px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.navdiv {
    margin-bottom: 20px;
    background-color: rgb(4, 65, 44);
    padding: 10px;
    border-style: solid;
    border-color: rgb(236, 229, 229);

}
nav a:hover {
    background-color: rgb(13, 133, 91);
  }