/*SHIMA */

form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    margin-top: 20px;
  }
  
  input {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    max-width: 100%;
    text-align: center;
  }
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: rgb(6, 93, 64);
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100px;
    max-width: 100%;
    cursor: pointer;
  }
  /* Add a hover effect for buttons */
  button:hover {
    background-color: rgb(9, 95, 66);
    opacity: 0.8;
  } 
  .btn {
    margin-top: 20px;
    padding: 10px;
    background-color: rgb(196, 123, 6);
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100px;
    max-width: 100%;
    cursor: pointer;
  }
  /* Add a hover effect for buttons */
  .btn:hover {
    background-color:  rgb(196, 123, 6);
    opacity: 0.8;
  } 
  /* The image used */
  .sql_img{
  background-image: url("SQL_injection.jpg");
  min-height: 60vh;
  min-width: 360px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  /* Center and scale the image  */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 8px 8px 15px 0 #ffffff, -5px -5px 7px 0 rgb(255, 255, 255),
        5px 2px 20px 0 rgb(24, 24, 23);
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  }
